import { ColorModeContext, useMode } from '../../theme';
import { CssBaseline, ThemeProvider } from '@mui/material';

import Sidebar from '../../scenes/global/Sidebar';
import Topbar from '../../scenes/global/Topbar';
import Camiones from '../../scenes/camiones';

const CamionesPage = () => {
    const [theme, colorMode] = useMode();
    return (
        <>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <div className="app">
                        <Sidebar />
                        <main className="content">
                            <Topbar />
                            <Camiones />
                        </main>
                    </div>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </>
    );
};

export default CamionesPage;
