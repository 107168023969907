import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Header from '../../components/Header';

function createData(plant, orderId, yards, hour, load, target) {
  return {
    plant, 
    orderId,
    yards,
    hour, 
    load, 
    target,
    history: [
      {
        date: '2023-01-05',
        concept: 'Diesel',
        unit: 3,
      },
      {
        date: '2023-04-02',
        concept: 'Grava',
        unit: 1,
      },
      {
        date: '2023-15-20',
        concept: 'Arena',
        unit: 1,
      },
      
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
        
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.plant}
        </TableCell>
        <TableCell align="right">{row.orderId}</TableCell>
        <TableCell align="right">{row.yards}</TableCell>
        <TableCell align="right">{row.hour}</TableCell>
        <TableCell align="right">{row.load}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Mix/Cost
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell align="right">Unit</TableCell>
                    <TableCell align="right">Total price ($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.concept}</TableCell>
                      <TableCell align="right">{historyRow.unit}</TableCell>
                      <TableCell align="right">
                        {Math.round(historyRow.unit * row.price * 100) / 100}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
           
          </Collapse>
         
        </TableCell>
      </TableRow>
    
    </React.Fragment>
    
   
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        unit: PropTypes.number.isRequired,
        concept: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData('Mission', 159, 6.0, 24, 4.0, 3.99),
  createData('Mission', 237, 9.0, 37, 4.3, 4.99),
  createData('San Benito', 262, 16.0, 24, 6.0, 3.79),
  createData('San Benito', 305, 3.7, 67, 4.3, 2.5),
  createData('San Benito', 356, 16.0, 49, 3.9, 1.5),
];

export default function CollapsibleTable() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Plant</TableCell>
            <TableCell align="right">OrderId</TableCell>
            <TableCell align="right">Yards</TableCell>
            <TableCell align="right">Hour</TableCell>
            <TableCell align="right">Load</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}