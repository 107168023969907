import axios from 'axios';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ErrorMessage from './Error';
import logo from './imagenes/logo-57Concrete.png';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const images = [
    { src: require('./imagenes/camiones.png'), alt: 'Camiones' },
    { src: require('./imagenes/planificador_plantas.png'), alt: 'Planificador de plantas' },
    { src: require('./imagenes/ordenes.png'), alt: 'Órdenes' },
    { src: require('./imagenes/drivers.png'), alt: 'Drivers' },
  ];

const Login = ({ setAuthToken }) => {
    const theme = createTheme();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [validForm, setValidForm] = useState(false);
    const [loginError, setLoginError] = useState(false);

    const validateForm = () => {
        if (email.length > 0 && password.length > 0) {
            setValidForm(true);
        } else {
            setValidForm(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoginError(false);

        const data = new FormData(e.currentTarget);
        const credentials = {
            email: data.get('email'),
            password: data.get('password'),
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/login`,
                credentials
            );
            let authToken = { token: response.data.data.token };
            setAuthToken(authToken);
            navigate('/dashboard', { replace: true });
        } catch (err) {
            setLoginError(true);
            console.log(err);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} sx={{ position: 'relative' }}>
          <Carousel autoPlay infiniteLoop>
            {images.map((image) => (
              <div key={image.src}>
                <img src={image.src} alt={image.alt} />
              </div>
            ))}
          </Carousel>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
        </Grid>
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square
                >
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ m: 10 }}>
                            <img src={logo} alt="Concreto 57 logo" />
                        </Box>
                        <Typography component="h1" variant="h5">
                            Welcome!
                        </Typography>
                        <Box
                            component="form"
                            noValidate
                            onSubmit={handleSubmit}
                            sx={{ mt: 1 }}
                        >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                type="email"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    validateForm();
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    validateForm();
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value="remember"
                                        color="primary"
                                    />
                                }
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                               // disabled={!validForm}
                            >
                                SIGN IN
                            </Button>
                            <Grid container>
                                <Grid item xs></Grid>
                            </Grid>
                        </Box>
                    </Box>
                    {loginError && <ErrorMessage />}
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

Login.propTypes = {
    setAuthToken: PropTypes.func.isRequired,
};

export default Login;
