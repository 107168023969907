import { useState } from 'react';
import { tokens } from '../../theme';
import 'react-pro-sidebar/dist/css/styles.css';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import imagen from './imagenes/logo-57Concrete.svg';
import back from './imagenes/57_bandera.svg';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import Truck from '@mui/icons-material/LocalShippingOutlined';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';


const Item = ({ title, to, icon, selected, setSelected }) => {
    return (
        <MenuItem
            active={selected === title}
            style={{ color: 'white' }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title} </Typography>
            <Link to={to} />
        </MenuItem>
    );
};

const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState('Dashboard');

    return (
        <Box
            sx={{
                '& .pro-sidebar-inner': {
                    backgroundImage: `url(${back})`,
                    backgroundSize: '50%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center bottom',
                },
            }}
        >
            <ProSidebar collapsed={isCollapsed} img="imagen">
                <Menu iconShape="square">
                    {/* LOGO e icono de menu */}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: '10px 0 20px 0',
                            color: colors.blueAccent[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <IconButton
                                    onClick={() => setIsCollapsed(!isCollapsed)}
                                >
                                    <MenuOutlinedIcon />
                                </IconButton>

                                <img alt="logo-57Concrete" src={imagen} />
                            </Box>
                        )}
                    </MenuItem>

                    {/*Usuario*/}
                    {!isCollapsed && (
                        <Box mb="25px" mt="5px">
                            <Box
                                display="flex"
                                justifyContent="center "
                                alignItems="center"
                            ></Box>
                        </Box>
                    )}
                    {/* menuitems */}

                    <Box paddingLeft={isCollapsed ? undefined : '10%'}>
                        <Item
                            title="Data Module"
                            to="/dashboard"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            style={{ color: 'white' }}
                        />

                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: '15px 0 5px 20px' }}
                        >
                            Information
                        </Typography>

                        <SubMenu
                            title="Plants"
                            icon={<DomainOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            style={{ color: 'white' }}
                        >
                            <Item
                                title="Mission"
                                to="/plants/1"
                                icon={<DomainOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="San Benito"
                                to="/plants/2"
                                icon={<DomainOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </SubMenu>

                        <Item
                            title="Drivers"
                            to="/drivers"
                            icon={<PeopleOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Item
                            title="Orders"
                            to="/orders"
                            icon={<ReceiptOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Item
                            title="Trucks"
                            to="/trucks"
                            icon={<Truck />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Item
                            title="Cost breakdown"
                            to="/costs"
                            icon={<RequestQuoteIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
};
export default Sidebar;
