import * as React from 'react';
import { useState } from 'react';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import AlertTitle from '@mui/material/AlertTitle';

const ErrorMessage = () => {
    const [open, setOpen] = useState(true);

    return (
        <Collapse in={open}>
            <Alert
                onClose={() => {
                    setOpen(false);
                }}
                severity="error"
            >
                <AlertTitle>
                    <strong>Error</strong>
                </AlertTitle>
                Email or password invalid, please try again.
            </Alert>
        </Collapse>
    );
};
export default ErrorMessage;
