import { useState } from 'react';

const useToken = () => {
    const getToken = () => {
        try {
            const tokenString = localStorage.getItem('AUTH_TOKEN');
            const userToken = JSON.parse(tokenString);
            return userToken?.token;
        } catch (err) {
            console.log(err);
            return undefined;
        }
    };

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken) => {
        localStorage.setItem('AUTH_TOKEN', JSON.stringify(userToken));
        setToken(userToken.token);
    };

    const removeToken = () => {
        localStorage.removeItem('AUTH_TOKEN');
        setToken(undefined);
    };

    return {
        setToken: saveToken,
        revokeToken: removeToken,
        token,
    };
};

export default useToken;
