import { ColorModeContext, useMode } from './theme';
import { Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';

import TeamPage from './components/Team';
import Login from './components/Login/Login';
import PlantasPage from './components/Plantas';
import PlantasPage1 from './components/plantauno';
import PlantasPage2 from './components/plantados';
import MixPage from './components/Mix';
import TablePage from './components/Table';
import CostsPage from './components/Costs';

import OrdenesPage from './components/Ordenes';
import CamionesPage from './components/Camiones';
import CalendarPage from './components/Calendar';
import DashboardPage from './components/Dashboard';
import useToken from './components/Utils/useToken';


function App() {
    const [theme, colorMode] = useMode();
    const { token, setToken, revokeToken } = useToken();

    if (!token) {
        return <Login setAuthToken={setToken} />;
    }

    return (
        <>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Routes>
                        <Route path="/dashboard" element={<DashboardPage />} />
                        <Route path="/drivers" element={<TeamPage />} />
                        <Route path="/plants" element={<PlantasPage />} />
                        <Route path="/plants/1" element={<PlantasPage1 />} />
                        <Route path="/plants/2" element={<PlantasPage2 />} />
                        <Route path="/orders" element={<OrdenesPage />} />
                        <Route path="/trucks" element={<CamionesPage />} />
                        <Route path="/calendar" element={<CalendarPage />} />
                        <Route path="/costs" element={<CostsPage />} />
                        <Route path="/mix" element={<MixPage />}/>
                        <Route path="/table" element={<TablePage /> } />
                        <Route
                            path="/login"
                            element={<Login setAuthToken={setToken} />}
                        />
                        <Route
                            path="/"
                            element={<Navigate to="/dashboard" replace={true} />}
                        />
                    </Routes>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </>
    );
}

export default App;
