import { useState, useCallback, useMemo } from 'react';
import {
    DataGrid,
    GridCellModes,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Box, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import PropTypes from 'prop-types';
import useToken from '../../components/Utils/useToken';
import axios from 'axios';
import TableLoader from '../../components/Custom/TableLoader';
import { useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CostFormDialog from '../../components/Custom/CostFormDialog';


function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

function EditToolbar(props) {
    const {
        selectedCellParams,
        cellMode,
        cellModesModel,
        setCellModesModel,
        title,
        subtitle,
        setOpenDialog,
        disableAdd,
    } = props;

    const handleSaveOrEdit = () => {
        if (!selectedCellParams) {
            return;
        }
        const { id, field } = selectedCellParams;
        if (cellMode === 'edit') {
            setCellModesModel({
                ...cellModesModel,
                [id]: {
                    ...cellModesModel[id],
                    [field]: { mode: GridCellModes.View },
                },
            });
        } else {
            setCellModesModel({
                ...cellModesModel,
                [id]: {
                    ...cellModesModel[id],
                    [field]: { mode: GridCellModes.Edit },
                },
            });
        }
    };

    const handleCancel = () => {
        if (!selectedCellParams) {
            return;
        }
        const { id, field } = selectedCellParams;
        setCellModesModel({
            ...cellModesModel,
            [id]: {
                ...cellModesModel[id],
                [field]: {
                    mode: GridCellModes.View,
                    ignoreModifications: true,
                },
            },
        });
    };

    const handleMouseDown = (event) => {
        // Keep the focus in the cell
        event.preventDefault();
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    return (
        <Box
            marginLeft={'10px'}
            sx={{
                borderBottom: 1,
                borderColor: 'divider',
                paddingLeft: '10px',
                paddingBottom: '10px',
            }}
        >
            <Header title={title} subtitle={subtitle} />
            <Button
                onClick={handleOpenDialog}
                onMouseDown={handleMouseDown}
                variant="contained"
                color="success"
                startIcon={<AddIcon />}
                disabled={disableAdd}
                sx={{ fontWeight: 'bold' }}
            >
                Add cost
            </Button>
            <Button
                onClick={handleSaveOrEdit}
                onMouseDown={handleMouseDown}
                disabled={!selectedCellParams}
                variant="outlined"
                sx={{ ml: 1, color: 'white', borderColor: 'black' }}
            >
                {cellMode === 'edit' ? 'Save' : 'Edit'}
            </Button>
            <Button
                onClick={handleCancel}
                onMouseDown={handleMouseDown}
                disabled={cellMode === 'view'}
                variant="outlined"
                sx={{ ml: 1, color: 'white', borderColor: 'black' }}
            >
                Cancel
            </Button>
        </Box>
    );
}

EditToolbar.propTypes = {
    cellMode: PropTypes.oneOf(['edit', 'view']).isRequired,
    cellModesModel: PropTypes.object.isRequired,
    selectedCellParams: PropTypes.shape({
        field: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            .isRequired,
    }),
    setCellModesModel: PropTypes.func.isRequired,
};

const Costs = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selectedCellParamsPrimary, setSelectedCellParamsPrimary] =
        useState(null);
    const [cellModesModelPrimary, setCellModesModelPrimary] = useState({});

    const [selectedCellParamsSecondary, setSelectedCellParamsSecondary] =
        useState(null);
    const [cellModesModelSecondary, setCellModesModelSecondary] = useState({});

    const [primaryRows, setPrimaryRows] = useState([]);
    const [secondaryRows, setSecondaryRows] = useState([]);
    const [primaryLoading, setPrimaryLoading] = useState(true);
    const [secondaryLoading, setSecondaryLoading] = useState(true);

    const { token, setToken, revokeToken } = useToken();
    const [searchParams, setSearchParams] = useSearchParams();

    const [primaryCounterIDs, setPrimaryCounterIDs] = useState({});
    const [secondaryCounterIDs, setSecondaryCounterIDs] = useState({});

    const [disableAdd, setDisableAdd] = useState(true);
    const [totalPrimary, setTotalPrimary] = useState(0);
    const [totalSecondary, setTotalSecondary] = useState(0);
    const [totalCost, setTotalCost] = useState(0);

    const [orderId, setOrderId] = useState(0);
    const [open, setOpen] = useState(false);

    const handleCellFocusPrimary = useCallback((event) => {
        const row = event.currentTarget.parentElement;
        const id = row.dataset.id;
        const field = event.currentTarget.dataset.field;
        setSelectedCellParamsPrimary({ id, field });
    }, []);

    const handleCellFocusSecondary = useCallback((event) => {
        const row = event.currentTarget.parentElement;
        const id = row.dataset.id;
        const field = event.currentTarget.dataset.field;
        setSelectedCellParamsSecondary({ id, field });
    }, []);

    const getDefaultPrimaryRows = async () => {
        setPrimaryRows([]);
        setPrimaryLoading(false);
    };

    const getDefaultSecondaryRows = async () => {
        setSecondaryRows([]);
        setSecondaryLoading(false);
    };

    const computeCounters = async (rows, fn) => {
        let counter = 1;
        let counterRows = {};
        for (let i = 0; i < rows.length; i++) {
            counterRows[rows[i].id] = counter;
            counter++;
        }
        fn(counterRows);
    };

    const jobId = searchParams.get('job');

    const urls = [
        `${process.env.REACT_APP_API_URL}/jobs/primaryCosts`,
        `${process.env.REACT_APP_API_URL}/jobs/secondaryCosts`,
    ];

    const requests = urls.map((url) =>
        axios.get(url, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
                job: jobId,
            },
        })
    );

    useMemo(() => {
        const dataFetch = async () => {
            Promise.all(requests).then(
                axios.spread((primary, secondary) => {
                    const pRows = primary.data.data.costs;
                    const sRows = secondary.data.data.costs;

                    computeCounters(pRows, setPrimaryCounterIDs);
                    computeCounters(sRows, setSecondaryCounterIDs);

                    setPrimaryRows(pRows);
                    setSecondaryRows(sRows);

                    setPrimaryLoading(false);
                    setSecondaryLoading(false);
                    
                    setTotalPrimary(primary.data.data.total);
                    setTotalSecondary(secondary.data.data.total);

                    const total = primary.data.data.total + secondary.data.data.total;
                    setTotalCost(total);

                    setOrderId(primary.data.data.number);
                })
            );
        };

        if (jobId) {
            setDisableAdd(false);
            dataFetch();
        } else {
            getDefaultPrimaryRows();
            getDefaultSecondaryRows();
        }
    }, []);

    const cellModePrimary = useMemo(() => {
        if (!selectedCellParamsPrimary) {
            return 'view';
        }
        const { id, field } = selectedCellParamsPrimary;
        return cellModesModelPrimary[id]?.[field]?.mode || 'view';
    }, [cellModesModelPrimary, selectedCellParamsPrimary]);

    const cellModeSecondary = useMemo(() => {
        if (!selectedCellParamsSecondary) {
            return 'view';
        }
        const { id, field } = selectedCellParamsSecondary;
        return cellModesModelSecondary[id]?.[field]?.mode || 'view';
    }, [cellModesModelSecondary, selectedCellParamsSecondary]);

    const handleCellKeyDownPrimary = useCallback(
        (params, event) => {
            if (cellModePrimary === 'edit') {
                // Prevents calling event.preventDefault() if Tab is pressed on a cell in edit mode
                event.defaultMuiPrevented = true;
            }
        },
        [cellModePrimary]
    );

    const handleCellKeyDownSecondary = useCallback(
        (params, event) => {
            if (cellModeSecondary === 'edit') {
                // Prevents calling event.preventDefault() if Tab is pressed on a cell in edit mode
                event.defaultMuiPrevented = true;
            }
        },
        [cellModeSecondary]
    );

    const handleCellEditStop = useCallback((params, event) => {
        event.defaultMuiPrevented = true;
    }, []);

    const handleAddRow = async (row) => {
        setSecondaryLoading(true);

        const dataRows = secondaryRows.filter((r) => r.isSummary === false);

        const postUrl = `${process.env.REACT_APP_API_URL}/jobs/addSecondaryCost`;
        const data = {
            id: jobId,
            secondaryCosts: dataRows,
            newCost: row,
        };

        const updatedJob = await axios.post(postUrl, data, {
            headers: { Authorization: `Bearer ${token}` },
        });

        const getUrl = `${process.env.REACT_APP_API_URL}/jobs/secondaryCosts`;
        const secondaryCosts = await axios.get(getUrl, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
                job: jobId,
            },
        })

        setTotalSecondary(secondaryCosts.data.data.total);
        setTotalCost(totalPrimary + secondaryCosts.data.data.total);

        const newRows = secondaryCosts.data.data.costs;
        computeCounters(newRows, setSecondaryCounterIDs);
        setSecondaryRows(newRows);
        setSecondaryLoading(false);
    };

    const primaryColumns = [
        {
            field: 'id',
            headerName: '',
            flex: 1,
            type: 'string',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            renderCell: (params) => (
                <Typography sx={{ fontSize: '10pt' }}>
                    {primaryCounterIDs[params.row.id]}
                </Typography>
            ),
        },
        {
            field: 'concept',
            headerName: 'Concept',
            flex: 2,
            editable: false,
            type: 'string',
            headerAlign: 'left',
            align: 'left',
            renderCell: (params) => (
                <Typography sx={{ fontSize: '10pt' }}>
                    {params.row.concept}
                </Typography>
            ),
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            type: 'number',
            flex: 1,
            headerAlign: 'left',
            align: 'left',
            editable: false,
            renderCell: (params) => (
                <Typography sx={{ fontSize: '10pt' }}>
                    {params.row.quantity} {params.row.unit}
                </Typography>
            ),
        },
        {
            field: 'yardCost',
            headerName: 'Cost per yard',
            type: 'number',
            flex: 1,
            editable: false,
            headerAlign: 'left',
            align: 'left',
            renderCell: (params) => (
                <Typography sx={{ fontSize: '10pt' }}>
                    {isNaN(params.row.yardCost) === true
                        ? params.row.yardCost
                        : `$${Number(params.row.yardCost).toFixed(4)}`}
                </Typography>
            ),
        },
        {
            field: 'total',
            headerName: 'Total cost',
            flex: 2,
            editable: false,
            type: 'number',
            headerAlign: 'left',
            align: 'left',
            renderCell: (params) => (
                <Typography sx={{ fontSize: '10pt' }}>
                    {isNaN(params.row.total) === true
                        ? params.row.total
                        : `$${Number(params.row.total).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                          })}`}
                </Typography>
            ),
        },
    ];

    const secondaryColumns = [
        {
            field: 'id',
            headerName: '',
            flex: 1,
            type: 'number',
            headerAlign: 'left',
            align: 'left',
            renderCell: (params) => (
                <Typography sx={{ fontSize: '10pt' }}>
                    {secondaryCounterIDs[params.row.id]}
                </Typography>
            ),
        },
        {
            field: 'concept',
            headerName: 'Concept',
            flex: 3,
            editable: true,
            type: 'string',
            headerAlign: 'left',
            align: 'left',
        },
        {
            field: 'total',
            headerName: 'Total cost',
            flex: 2,
            editable: true,
            type: 'number',
            headerAlign: 'left',
            align: 'left',
            renderCell: (params) => (
                <Typography color={colors.greenAccent[200]}>
                    {isNaN(params.row.total) === true
                        ? params.row.total
                        : `$${Number(params.row.total).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                          })}`}
                </Typography>
            ),
        },
        {
            field: 'comment',
            headerName: 'Comments',
            flex: 3,
            editable: true,
            type: 'string',
            headerAlign: 'left',
            align: 'left',
        },
    ];

    return (
        <Box
            m="25px"
            sx={{
                height: 'calc(100vh - 17rem)',
                p: 1,
            }}
        >
            <Grid container spacing={2} columns={16} marginBottom={'-2rem'}>
                <Grid item xs={10}>
                    <Header
                        title="Job cost breakdown"
                        subtitle={
                            <Grid container spacing={0} columns={24}>
                                <Grid item xs={20}>
                                    <Typography sx={{ fontSize: '12pt', paddingTop: '10px' }}>
                                        {`Total cost for job number ${orderId} (primary plus secondary costs):`}
                                    </Typography>
                                </Grid>
                                <Box
                                        sx={{
                                            border: '2px solid darkred',
                                            borderColor: colors.redAccent[1000],
                                            padding: '10px',
                                            borderRadius: '16px',
                                            color: colors.redAccent[1000],
                                            marginLeft: '10px',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12pt',
                                                fontWeight: 'bold',
                                                color: colors.redAccent[400],
                                            }}
                                        >
                                            {`$${Number(
                                                totalCost
                                            ).toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                            })}`}
                                        </Typography>
                                    </Box>
                            </Grid>
                        }
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} columns={16} sx={{ height: '100%' }}>
                <Grid item xs={8}>
                    <DataGrid
                        rows={primaryRows}
                        columns={primaryColumns}
                        onCellKeyDown={handleCellKeyDownPrimary}
                        cellModesModel={cellModesModelPrimary}
                        loading={primaryLoading}
                        hideFooterPagination
                        getCellClassName={(params) => {
                            if (params.row.isSummary) {
                                return 'MuiDataGrid-cell--summary';
                            } else {
                                return 'MuiDataGrid-cell';
                            }
                        }}
                        slots={{
                            toolbar: () => (
                                <>
                                    <Box
                                        sx={{
                                            marginTop: '10px',
                                            marginLeft: '10px',
                                            paddingLeft: '10px',
                                            paddingTop: '10px',
                                            marginBottom: '-20px'
                                        }}
                                    >
                                        <Header
                                            title="Primary costs"
                                        />
                                    </Box>
                                    <CustomToolbar />
                                </>
                            ),
                            loadingOverlay: TableLoader,
                        }}
                        sx={{
                            '& .MuiDataGrid-root': {
                                border: 'none',
                            },
                            '& .MuiDataGrid-cell': {
                                borderBottom: 'none',
                            },
                            '& .MuiDataGrid-cell--summary': {
                                color: colors.greenAccent[100],
                                backgroundColor: colors.greenAccent[800],
                                '&:hover': {
                                    backgroundColor: colors.greenAccent[900],
                                },
                            },
                            '& .name-column--cell': {
                                color: '#4caf50' /* Green 500 */,
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: '#24438B' /* Indigo 500 */,
                                borderBottom: 'none',
                            },
                            '& .MuiDataGrid-virtualScroller': {
                                /*backgroundColor: '#9575cd'  Deep Purple 300 */
                            },
                            '& .MuiDataGrid-footerContainer': {
                                borderTop: 'none',
                                backgroundColor: '#303f9f' /* Indigo 500 */,
                            },
                            '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                                color: '#fafafa' /* Grey 50 */,
                            },
                        }}
                        slotProps={{
                            toolbar: {
                                cellModePrimary,
                                selectedCellParamsPrimary,
                                setSelectedCellParamsPrimary,
                                cellModesModelPrimary,
                                setCellModesModelPrimary,
                            },
                            cell: {
                                onFocus: handleCellFocusPrimary,
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={8}>
                    <DataGrid
                        rows={secondaryRows}
                        columns={secondaryColumns}
                        onCellKeyDown={handleCellKeyDownSecondary}
                        cellModesModel={cellModesModelSecondary}
                        onCellEditStop={handleCellEditStop}
                        loading={secondaryLoading}
                        hideFooterPagination
                        onCellModesModelChange={(model) =>
                            setCellModesModelSecondary(model)
                        }
                        getCellClassName={(params) => {
                            if (params.row.isSummary) {
                                return 'MuiDataGrid-cell--summary';
                            } else {
                                return 'MuiDataGrid-cell';
                            }
                        }}
                        slots={{
                            toolbar: () => (
                                <>
                                    <Box
                                        sx={{
                                            marginTop: '10px',
                                            marginLeft: '10px',
                                            paddingLeft: '10px',
                                            paddingTop: '10px',
                                            marginBottom: '-20px'
                                        }}
                                    >
                                        <Header
                                            title="Secondary costs"
                                        />
                                    </Box>
                                    <EditToolbar
                                        selectedCellParams={
                                            selectedCellParamsSecondary
                                        }
                                        cellMode={cellModeSecondary}
                                        cellModesModel={cellModesModelSecondary}
                                        setCellModesModel={
                                            setCellModesModelSecondary
                                        }
                                        setOpenDialog={setOpen}
                                        disableAdd={disableAdd}
                                    />
                                    <CustomToolbar />
                                </>
                            ),
                            loadingOverlay: TableLoader,
                        }}
                        sx={{
                            '& .MuiDataGrid-root': {
                                border: 'none',
                            },
                            '& .MuiDataGrid-cell': {
                                borderBottom: 'none',
                            },
                            '& .name-column--cell': {
                                color: '#4caf50' /* Green 500 */,
                            },
                            '& .MuiDataGrid-cell--summary': {
                                color: colors.greenAccent[100],
                                backgroundColor: colors.greenAccent[800],
                                '&:hover': {
                                    backgroundColor: colors.greenAccent[900],
                                },
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: '#24438B' /* Indigo 500 */,
                                borderBottom: 'none',
                            },
                            '& .MuiDataGrid-virtualScroller': {
                                /*backgroundColor: '#9575cd'  Deep Purple 300 */
                            },
                            '& .MuiDataGrid-footerContainer': {
                                borderTop: 'none',
                                backgroundColor: '#303f9f' /* Indigo 500 */,
                            },
                            '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                                color: '#fafafa' /* Grey 50 */,
                            },
                        }}
                        slotProps={{
                            toolbar: {
                                cellModeSecondary,
                                selectedCellParamsSecondary,
                                setSelectedCellParamsSecondary,
                                cellModesModelSecondary,
                                setCellModesModelSecondary,
                            },
                            cell: {
                                onFocus: handleCellFocusSecondary,
                            },
                        }}
                    />
                    {open && (
                        <CostFormDialog
                            open={open}
                            setOpen={setOpen}
                            orderId={orderId}
                            handleAddRow={handleAddRow}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default Costs;
