import { useState, useCallback, useMemo } from 'react';
import { DataGrid, GridCellModes,GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import {Box, Typography, useTheme} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import PropTypes from 'prop-types';

import axios from 'axios';
import useToken from '../../components/Utils/useToken';
import formatPhoneNumber from '../../components/Utils/phoneRegex';

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FilterListIcon from "@mui/icons-material/FilterList";
import TableLoader from '../../components/Custom/TableLoader';


function CustomToolbar() {
  return (
    <GridToolbar
      densityIcon={<ChevronRightIcon />}
      disableDensitySelector
      columnsIcon={<FilterListIcon />}
    />
  );
}

function EditToolbar(props) {
    const { selectedCellParams, cellMode, cellModesModel, setCellModesModel } = props;
  const theme = useTheme();
     const colors = tokens(theme.palette.mode);
     
    const handleSaveOrEdit = () => {
      if (!selectedCellParams) {
        return;
      }
      const { id, field } = selectedCellParams;
      if (cellMode === 'edit') {
        setCellModesModel({
          ...cellModesModel,
          [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.View } },
        });
        alert('Changes saved successfully');
      } else {
        setCellModesModel({
          ...cellModesModel,
          [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.Edit } },
        });
        
      }
    };
  
    const handleCancel = () => {
      if (!selectedCellParams) {
        return;
      }
      const { id, field } = selectedCellParams;
      setCellModesModel({
        ...cellModesModel,
        [id]: {
          ...cellModesModel[id],
          [field]: { mode: GridCellModes.View, ignoreModifications: true },
        },
      });
      alert('Changes cancelled');

    };
  
    const handleMouseDown = (event) => {
      // Keep the focus in the cell
      event.preventDefault();
    };
  
    return (
      <Box m= "20px">
        <Header
          title="Drivers"
          subtitle="Driver hours Status Review Board"
          />
        
        <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          p: 1,
        }}
      >
        <Button
          onClick={handleSaveOrEdit}
          onMouseDown={handleMouseDown}
          disabled={!selectedCellParams}
          variant="outlined"

  sx={{ color: 'white', borderColor: 'black' }}
        >
          {cellMode === 'edit' ? 'Save' : 'Edit'}
        </Button>
        <Button
          onClick={handleCancel}
          onMouseDown={handleMouseDown}
          disabled={cellMode === 'view'}
          variant="outlined"
          sx={{ ml: 1, color: 'white', borderColor: 'black'  }}
        >

          Cancel
        </Button>
        
      </Box>
      </Box>
    );
  }
  
  EditToolbar.propTypes = {
    cellMode: PropTypes.oneOf(['edit', 'view']).isRequired,
    cellModesModel: PropTypes.object.isRequired,
    selectedCellParams: PropTypes.shape({
      field: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
    setCellModesModel: PropTypes.func.isRequired,
  };
  
  const Drivers = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selectedCellParams, setSelectedCellParams] = useState(null);
    const [cellModesModel, setCellModesModel] = useState({});

    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { token, setToken, revokeToken } = useToken();

    const url = `${process.env.REACT_APP_API_URL}/drivers/list`;
    const requests = [
        axios.get(url, {
            headers: { Authorization: `Bearer ${token}` },
        }),
    ];

    useMemo(() => {
        const dataFetch = async () => {
            Promise.all(requests).then((response) => {
                setRows(response[0].data.data);
                setLoading(false);
            });
        };
        dataFetch();
    }, []);
  
    const handleCellFocus = useCallback((event) => {
      const row = event.currentTarget.parentElement;
      const id = row.dataset.id;
      const field = event.currentTarget.dataset.field;
      setSelectedCellParams({ id, field });
    }, []);
  
    const cellMode = useMemo(() => {
      if (!selectedCellParams) {
        return 'view';
      }
      const { id, field } = selectedCellParams;
      return cellModesModel[id]?.[field]?.mode || 'view';
    }, [cellModesModel, selectedCellParams]);
  
    const handleCellKeyDown = useCallback(
      (params, event) => {
        if (cellMode === 'edit') {
          // Prevents calling event.preventDefault() if Tab is pressed on a cell in edit mode
          event.defaultMuiPrevented = true;
        }
      },
      [cellMode],
    );

    const columns = [
      {  
          field: "name",
          headerName:"Name",
          headerAlign: "left",
          align: "left",
          editable: true,
          flex: 1,
      },      
      {
          field: "hours",
          headerName: "Hours",
          type: 'number',
          editable: true,
          headerAlign: "left",
          align: "left",
          flex: 1,
          renderCell: (params) => (
            <Typography color={colors.greenAccent[200]}>
                {params.row.hours}
            </Typography>
          ),
      },
      {
          field: "truck",
          headerName: "Truck",
          type: "string",
          editable: true,
          headerAlign: "left",
          align: "left",
          flex: 1,
      },
      {
          field: "plant",
          headerName: "Plant",
          type: "string",
          editable: true,
          headerAlign: "left",
          align: "left",
          flex: 1,
      },
      {
          field: "phone",
          headerName: "Phone",
          type: "string",
          editable: true,
          headerAlign: "left",
          align: "left",
          flex: 1,
          renderCell: (params) => (
            <Typography color={colors.greenAccent[400]}>
                {formatPhoneNumber(params.row.phone)}
            </Typography>
          ),
      },
      {
          field: "comments",
          headerName: "Comments",
          type: "string",
          editable: true,
          headerAlign: "left",
          align: "left",
          flex: 2,
      }
    ];
  
    return (
      <div style={{ height: '77%', width: '97%', marginLeft: '2em' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          onCellKeyDown={handleCellKeyDown}
          cellModesModel={cellModesModel}
          onCellModesModelChange={(model) => setCellModesModel(model)}
          loading={loading}
          getRowId={(row) => row.driverId}
          slots={{
            toolbar: () => (
              <>
                <EditToolbar
                  selectedCellParams={selectedCellParams}
                  cellMode={cellMode}
                  cellModesModel={cellModesModel}
                  setCellModesModel={setCellModesModel}
                />
                <CustomToolbar />
              </>
            ),
            loadingOverlay: TableLoader,
          }}
          sx={{ 
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell":{
              borderBottom: "none"
            },
            "& .name-column--cell":{
              color: '#4caf50' /* Green 500 */,
            },
            "& .MuiDataGrid-columnHeaders":{
              backgroundColor: '#24438B' /* Indigo 500 */,
              borderBottom:"none"
            },
            "& .MuiDataGrid-virtualScroller": {
              /*backgroundColor: '#9575cd'  Deep Purple 300 */
            },
            "& .MuiDataGrid-footerContainer":{
              borderTop: "none",
              backgroundColor: '#303f9f' /* Indigo 500 */,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: '#fafafa' /* Grey 50 */,
            }
          }}


          slotProps={{
            toolbar: {
              cellMode,
              selectedCellParams,
              setSelectedCellParams,
              cellModesModel,
              setCellModesModel,
            },
            cell: {
              onFocus: handleCellFocus,
            },
          }}
        />
      </div>
    );
  };

export default Drivers;
