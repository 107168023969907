import {
    DataGrid,
    GridToolbar,
    GridCellModes,
    GridCellEditStopReasons,
} from '@mui/x-data-grid';
import { Box, Typography, useTheme, Button } from '@mui/material';
import { tokens, ColorModeContext, useMode } from '../../theme';
import Header from '../../components/Header';
import { useState, useMemo, useCallback } from 'react';
import useToken from '../../components/Utils/useToken';
import TableLoader from '../../components/Custom/TableLoader';
import axios from 'axios';
import PropTypes from 'prop-types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FilterListIcon from '@mui/icons-material/FilterList';

function CustomToolbar() {
    return (
        <GridToolbar
            densityIcon={<ChevronRightIcon />}
            disableDensitySelector
            columnsIcon={<FilterListIcon />}
            // Add more props if needed
        />
    );
}

const plants = Object.freeze({
    'Mission': '1',
    'San Benito': '2',
});

const EditToolbar = (props) => {
    const {
        selectedCellParams,
        cellMode,
        cellModesModel,
        setCellModesModel,
        plant,
    } = props;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleSaveOrEdit = () => {
        if (!selectedCellParams) {
            return;
        }
        const { id, field } = selectedCellParams;
        if (cellMode === 'edit') {
            setCellModesModel({
                ...cellModesModel,
                [id]: {
                    ...cellModesModel[id],
                    [field]: { mode: GridCellModes.View },
                },
            });
            alert('Guardado exitosamente');
        } else {
            setCellModesModel({
                ...cellModesModel,
                [id]: {
                    ...cellModesModel[id],
                    [field]: { mode: GridCellModes.Edit },
                },
            });
        }
    };

    const handleCancel = () => {
        if (!selectedCellParams) {
            return;
        }
        const { id, field } = selectedCellParams;
        setCellModesModel({
            ...cellModesModel,
            [id]: {
                ...cellModesModel[id],
                [field]: {
                    mode: GridCellModes.View,
                    ignoreModifications: true,
                },
            },
        });
        alert('Cambios cancelados');
    };

    const handleMouseDown = (event) => {
        // Keep the focus in the cell
        event.preventDefault();
    };

    return (
        <Box m="20px">
            <Header
                title={`Plant Data - ${plant}`}
                subtitle="Plant Review Board"
            />
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >
                <Button
                    onClick={handleSaveOrEdit}
                    onMouseDown={handleMouseDown}
                    disabled={!selectedCellParams}
                    variant="outlined"
                    sx={{ color: 'white', borderColor: 'black' }}
                >
                    {cellMode === 'edit' ? 'Save' : 'Edit'}
                </Button>
                <Button
                    onClick={handleCancel}
                    onMouseDown={handleMouseDown}
                    disabled={cellMode === 'view'}
                    variant="outlined"
                    sx={{ ml: 1, color: 'white', borderColor: 'black' }}
                >
                    Cancel
                </Button>
            </Box>
        </Box>
    );
};

EditToolbar.propTypes = {
    cellMode: PropTypes.oneOf(['edit', 'view']).isRequired,
    cellModesModel: PropTypes.object.isRequired,
    selectedCellParams: PropTypes.shape({
        field: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            .isRequired,
    }),
    setCellModesModel: PropTypes.func.isRequired,
};

const Plants = ({ plant }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { token, setToken, revokeToken } = useToken();

    let url = `${process.env.REACT_APP_API_URL}/planner/summary`;
    if (plant !== undefined) {
        const plantId = plants[plant];
        url += `/${plantId}`;
    } 
    
    const requests = [
        axios.get(url, {
            headers: { Authorization: `Bearer ${token}` },
        }),
    ];

    useMemo(() => {
        const dataFetch = async () => {
            Promise.all(requests).then((response) => {
                setData(response[0].data.data);
                setLoading(false);
            });
        };
        dataFetch();
    }, []);

    const [selectedCellParams, setSelectedCellParams] = useState(null);
    const [cellModesModel, setCellModesModel] = useState({});

    const handleCellFocus = useCallback((event) => {
        const row = event.currentTarget.parentElement;
        const id = row.dataset.id;
        const field = event.currentTarget.dataset.field;
        setSelectedCellParams({ id, field });
    }, []);

    const cellMode = useMemo(() => {
        if (!selectedCellParams) {
            return 'view';
        }
        const { id, field } = selectedCellParams;
        return cellModesModel[id]?.[field]?.mode || 'view';
    }, [cellModesModel, selectedCellParams]);

    const handleCellKeyDown = useCallback(
        (params, event) => {
            if (cellMode === 'edit') {
                // Prevents calling event.preventDefault() if Tab is pressed on a cell in edit mode
                event.defaultMuiPrevented = true;
            }
        },
        [cellMode]
    );

    const handleCellEditStop = useCallback((params, event) => {
        event.defaultMuiPrevented = true;
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'Order',
            type: 'string',
            flex: 1,
        },
        {
            field: 'client',
            headerName: 'Client',
            type: 'string',
            flex: 3,
        },
        {
            field: 'yards',
            headerName: 'Yards',
            cellClassName: 'name-column--cell',
            type: 'number',
            flex: 2,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'time',
            headerName: 'Time',
            type: 'string',
            flex: 2,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => (
                <Typography color={colors.greenAccent[500]}>
                    {params.row.time}
                </Typography>
            ),
        },
        {
            field: 'cost',
            headerName: 'Cost',
            type: 'number',
            flex: 2,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => (
                <Typography color={colors.greenAccent[200]}>
                    {isNaN(params.row.cost) === true
                        ? params.row.cost
                        : `$${Number(params.row.cost).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                          })}`}
                </Typography>
            ),
        },
        {
            field: 'comments',
            headerName: 'Comments',
            type: 'string',
            editable: true,
            flex: 2,
            align: 'left',
        },
    ];

    return (
        <div style={{ height: '77%', width: '97%', marginLeft: '2em' }}>
            <DataGrid
                rows={data}
                columns={columns}
                onCellKeyDown={handleCellKeyDown}
                cellModesModel={cellModesModel}
                onCellEditStop={handleCellEditStop}
                onCellModesModelChange={(model) => setCellModesModel(model)}
                loading={loading}
                slots={{
                    toolbar: () => (
                        <>
                            <EditToolbar
                                selectedCellParams={selectedCellParams}
                                cellMode={cellMode}
                                cellModesModel={cellModesModel}
                                setCellModesModel={setCellModesModel}
                                plant={plant !== undefined ? plant : 'All'}
                            />
                            <CustomToolbar />
                        </>
                    ),
                    loadingOverlay: TableLoader,
                }}
                sx={{
                    '& .MuiDataGrid-root': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    '& .name-column--cell': {
                        color: '#4caf50' /* Green 500 */,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#24438B' /* Indigo 500 */,
                        borderBottom: 'none',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        /*backgroundColor: '#9575cd'  Deep Purple 300 */
                    },
                    '& .MuiDataGrid-footerContainer': {
                        borderTop: 'none',
                        backgroundColor: '#303f9f' /* Indigo 500 */,
                    },
                    '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                        color: '#fafafa' /* Grey 50 */,
                    },
                }}
                slotProps={{
                    toolbar: {
                        cellMode,
                        selectedCellParams,
                        setSelectedCellParams,
                        cellModesModel,
                        setCellModesModel,
                    },
                    cell: {
                        onFocus: handleCellFocus,
                    },
                }}
            />
        </div>
    );
};

export default Plants;
