import { Box, Grid, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import fotoCamiones from './imagenes/camiones.png';
import fotoPlanificadora from './imagenes/planificador_plantas.png';
import fotoOrdenes from './imagenes/ordenes.png';
import fotoDrivers from './imagenes/drivers.png';
import { Link } from 'react-router-dom';

const DashboardCard = ({ link, src, alt, title, content }) => {
    return (
        <Card sx={{ maxWidth: 900, margin: 2 }}>
            <CardActionArea component={Link} to={link}>
                <CardMedia
                    component="img"
                    src={src}
                    alt={alt}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" fontWeight="bold">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                        {content}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box m="20px">
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Header
                    title="Plant Intelligence Module"
                    subtitle="Information"
                />
            </Box>
            <Box
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ height: 'calc(100vh - 20rem)' }}
            >
                <Grid container columns={16} justify="center" sx={{ mx: 3 }}>
                    <Grid item xs={4} sx={{ p: 1 }}>
                        <DashboardCard 
                            link="/plants"
                            src={fotoPlanificadora}
                            alt="fotoPlanificadora"
                            title="Plant Planner"
                            content="Plant plan review board"
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ p: 1 }}>
                        <DashboardCard 
                            link="/trucks"
                            src={fotoCamiones}
                            alt="fotoCamiones"
                            title="Trucks"
                            content="Truck status information board"
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ p: 1 }}>
                        <DashboardCard 
                            link="/drivers"
                            src={fotoDrivers}
                            alt="fotoDrivers"
                            title="Drivers"
                            content="Driver hours status review board"
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ p: 1 }}>
                        <DashboardCard 
                            link="/orders"
                            src={fotoOrdenes}
                            alt="fotoOrdenes"
                            title="Orders"
                            content="Order status review board"
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Dashboard;
