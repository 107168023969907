import { useState, forwardRef } from 'react';

import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
            prefix="$"
        />
    );
});

NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const CostFormDialog = ({ open, setOpen, orderId, serverDone, handleAddRow }) => {
    const [concept, setConcept] = useState('');
    const [total, setTotal] = useState(0);
    const [comment, setComment] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            "concept": concept,
            "total": total,
            "comment": comment,
        }

        handleAddRow(data);
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog open={open} >
                <DialogTitle>Add secondary cost</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Fill in the form below to add a secondary cost to the
                        order number {orderId}.
                    </DialogContentText>
                    <TextField
                        required
                        autoFocus
                        margin="dense"
                        id="concept"
                        label="Concept"
                        type="text"
                        fullWidth
                        variant="filled"
                        color="secondary"
                        onChange={(e) => {
                            setConcept(e.target.value);
                        }}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="total"
                        label="Total cost"
                        fullWidth
                        variant="filled"
                        color="secondary"
                        InputProps={{
                            inputComponent: NumericFormatCustom,
                        }}
                        onChange={(e) => {
                            setTotal(e.target.value);
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="comment"
                        label="Comments"
                        type="text"
                        fullWidth
                        variant="filled"
                        color="secondary"
                        onChange={(e) => {
                            setComment(e.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleCancel}>Cancel</Button>
                    <Button variant="outlined" color="secondary" onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CostFormDialog;
