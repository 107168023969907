import { Box, IconButton, useTheme } from '@mui/material';
import { useState, useContext, useMemo } from 'react';
import { ColorModeContext, tokens } from '../../theme';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import iconoCamiones from './imagenes/icono_camiones.svg';
import iconoOrdenes from './imagenes/icono_ordenes.svg';
import iconoTerminado from './imagenes/icono_terminado.svg';
import iconoRetardos from './imagenes/icono_retardos.svg';

import iconoYardas from './imagenes/icono_yardas.svg';
import StatBox from '../../components/StatBox';
import { useNavigate } from 'react-router-dom';
import useToken from '../../components/Utils/useToken';

import axios from 'axios';
import MetricLoader from '../../components/Custom/MetricLoader';

const Topbar = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    const [jobs, setJobs] = useState('');
    const [yards, setYards] = useState('');
    const [trucks, setTrucks] = useState('');
    const [jobsDone, setJobsDone] = useState('');
    const [delayedLoads, setDelayedLoads] = useState('');

    const [jobTrigger, setJobTrigger] = useState(false);
    const [yardTrigger, setYardTrigger] = useState(false);
    const [truckTrigger, setTruckTrigger] = useState(false);
    const [jobsDoneTrigger, setJobsDoneTrigger] = useState(false);
    const [delayedTrigger, setDelayedTrigger] = useState(false);
    const { token, setToken, revokeToken } = useToken();

    const checkEmptyMetrics = () => {
        if (jobs || jobs == 0) {
            setJobTrigger(true);
        }

        if (yards || yards == 0) {
            setYardTrigger(true);
        }

        if (trucks || trucks == 0) {
            setTruckTrigger(true);
        }

        if (jobsDone || jobsDone == 0) {
            setJobsDoneTrigger(true);
        }

        if (delayedLoads || delayedLoads == 0) {
            setDelayedTrigger(true);
        }
    };

    const urls = [
        `${process.env.REACT_APP_API_URL}/jobs/jobCount`,
        `${process.env.REACT_APP_API_URL}/jobs/totalYD3`,
        `${process.env.REACT_APP_API_URL}/loads/trucksAvailable`,
        `${process.env.REACT_APP_API_URL}/jobs/doneJobs`,
        `${process.env.REACT_APP_API_URL}/loads/delayed`,
    ];

    const requests = urls.map((url) =>
        axios.get(url, {
            headers: { Authorization: `Bearer ${token}` },
        })
    );

    const handleClick = (e) => {
        revokeToken();
        navigate('/login', { replace: true });
    };

    useMemo(() => {
        const dataFetch = async () => {
            Promise.all(requests).then(
                axios.spread(
                    (
                        jobCount,
                        totalYD3,
                        trucksAvailable,
                        doneJobs,
                        delayed
                    ) => {
                        setJobs(jobCount.data.data.jobCount);
                        setYards(totalYD3.data.data.totalYD3);
                        setTrucks(trucksAvailable.data.data.truckCount);
                        setJobsDone(doneJobs.data.data.jobsDone);
                        setDelayedLoads(delayed.data.data.delayedLoads);
                        checkEmptyMetrics();
                    }
                )
            );
        };
        dataFetch();
    }, []);

    return (
        <Box m="30px">
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ minWidth: '300px' }}
            >
                <Box
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        title="Trucks"
                        subtitle={truckTrigger ? trucks : <MetricLoader />}
                        icon={
                            <img
                                src={iconoCamiones}
                                alt="icono_camiones"
                                width="40"
                                height="40"
                                style={{ maringRight: '8px' }}
                                sx={{
                                    color: colors.greenAccent[600],
                                    fontSize: '10px',
                                }}
                            />
                        }
                    />
                </Box>

                <Box
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        title="Total YD"
                        subtitle={yardTrigger ? yards : <MetricLoader />}
                        icon={
                            <img
                                src={iconoYardas}
                                alt="iconoYardas"
                                width="40"
                                height="40"
                                style={{ maringRight: '8px' }}
                                sx={{
                                    color: colors.greenAccent[600],
                                    fontSize: '10px',
                                }}
                            />
                        }
                    />
                </Box>

                <Box
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        title="Total Orders"
                        subtitle={jobTrigger ? jobs : <MetricLoader />}
                        icon={
                            <img
                                src={iconoOrdenes}
                                alt="icono_ordenes"
                                width="40"
                                height="40"
                                style={{ maringRight: '8px' }}
                                sx={{
                                    color: colors.greenAccent[600],
                                    fontSize: '10px',
                                }}
                            />
                        }
                    />
                </Box>

                <Box
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        title="Completed"
                        subtitle={jobsDoneTrigger ? jobsDone : <MetricLoader />}
                        icon={
                            <img
                                src={iconoTerminado}
                                alt="icono_terminado"
                                width="40"
                                height="40"
                                style={{ maringRight: '8px' }}
                                sx={{
                                    color: colors.greenAccent[600],
                                    fontSize: '10px',
                                }}
                            />
                        }
                    />
                </Box>
                <Box
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        title="Delays"
                        subtitle={
                            delayedTrigger ? delayedLoads : <MetricLoader />
                        }
                        icon={
                            <img
                                src={iconoRetardos}
                                alt="icono_retardos"
                                width="40"
                                height="40"
                                style={{ maringRight: '8px' }}
                                sx={{
                                    color: colors.greenAccent[600],
                                    fontSize: '10px',
                                }}
                            />
                        }
                    />
                </Box>

                {/* iconos*/}
                <Box display="flex">
                    <IconButton onClick={colorMode.toggleColorMode}>
                        {theme.palette.mode === 'dark' ? (
                            <DarkModeOutlinedIcon />
                        ) : (
                            <LightModeOutlinedIcon />
                        )}
                    </IconButton>
                    <IconButton onClick={handleClick}>
                        <PersonOutlinedIcon />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

export default Topbar;
