import {useState} from "react";
import { formatDate } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from '@fullcalendar/list';
import Header from "../../components/Header";
import { tokens } from "../../theme";
import{
    Box,
    List,
    ListItem,
    ListItemText,
    Typography,
    useTheme,
} from "@mui/material";
import { FormatIndentDecreaseTwoTone } from "@mui/icons-material";

const Calendar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [currentEvents, setCurrentEvents] = useState([]);

    const handleDateClick = (selected) => {
        const title = prompt("Ingresa un nuevo evento");
        const calendarApi = selected.view.calendar;
        calendarApi.unselect();

        if (title){
            calendarApi.addEvent({
                id: `${selected.dateStr}-${title}`,
                title,
                start: selected.startStr,
                end: selected.endStr,
                allDay: selected.allDay,
            });
        }
    };

    const handleEventClick = (selected) => {
        if (
          window.confirm(
            `Se eliminara el evento '${selected.event.title}'`
          )
        ) {
          selected.event.remove();
        }
      };

      return(
       <Box m="20px">
          <Header title="Calendario" subtitle= "Calendario interactico" />
 {/*calendario */}
        <Box display="flex" justifyContent="space-between">
      <Box flex="1 1 20%"
      backgroundColor={colors.primary[400]}
      p="15px"
      borderRadius="4px" >

          <Typography variant="h5">Eventos</Typography>
          <List>
              {currentEvents.map((event) => (
                  <ListItem 
                  key={event.id}
                  sx={{ backgroundColor: colors.greenAccent[500],
                     margin: "10px 0", 
                     borderRadius: "2px",
                    }}
                    >
                        <ListItemText
                            primary={event.title}
                            secondary={
                                <Typography>
                                     {formatDate(event.start, {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                                    })
                                }
                                    
                                </Typography>
                            }
                        >
                        </ListItemText>

                    </ListItem>
             ))}

          </List>
      </Box>    

{/* calendario */}
        <Box flex="1 1 100%" ml="15px">
            <FullCalendar
                height="75vh"
                plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin,
                    listPlugin
                ]}

                headerToolbar={{
                    left: "prev, next today",
                    center: "title",
                    right: "dayGridMonth, timeGridWeek, timeGridDay, listMonth"
                }}
                initialView="dayGridMonth"
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    select={handleDateClick}
                    eventClick={handleEventClick}
                    eventsSet={(events) => setCurrentEvents(events)}
                    initialEvents={[
                        {id: "1234", title: "Camion 23 llego", date: "2023-04-05"},
                        {
                         id: "4321", title: "Camion 11 salio", date: "2023-04-05"
                        },
                        {id:"89898", title:"Primera carga", date: "2023-04-02"},
                    ]}
            />
        </Box>
    </Box>
 </Box>
      );
};
export default Calendar;