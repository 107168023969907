import { ColorModeContext, useMode } from '../../theme';
import { CssBaseline, ThemeProvider } from '@mui/material';

import Sidebar from '../../scenes/global/Sidebar';
import Topbar from '../../scenes/global/Topbar';
import Plantas from '../../scenes/Plantas';

const PlantasPage1 = () => {
    const [theme, colorMode] = useMode();
    return (
        <>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <div className="app">
                        <Sidebar />
                        <main className="content">
                            <Topbar />
                            <Plantas plant={"Mission"}/>
                        </main>
                    </div>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </>
    );
};

export default PlantasPage1;