import { useState, useCallback, useMemo, useEffect } from 'react';
import {
    DataGrid,
    GridCellModes,
    GridToolbar,
    useGridApiContext,
} from '@mui/x-data-grid';
import { Box, Typography, useTheme, Select } from '@mui/material';
import Button from '@mui/material/Button';
import { tokens } from '../../theme';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Header from '../../components/Header';
import PropTypes from 'prop-types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FilterListIcon from '@mui/icons-material/FilterList';
import useToken from '../../components/Utils/useToken';
import axios from 'axios';
import TableLoader from '../../components/Custom/TableLoader';


function CustomToolbar() {
    return (
        <GridToolbar
            densityIcon={<ChevronRightIcon />}
            disableDensitySelector
            columnsIcon={<FilterListIcon />}
        />
    );
}

function EditToolbar(props) {
    const { selectedCellParams, cellMode, cellModesModel, setCellModesModel } =
        props;

    const apiRef = useGridApiContext();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleSaveOrEdit = () => {
        if (!selectedCellParams) {
            return;
        }
        const { id, field } = selectedCellParams;
        if (cellMode === 'edit') {
            //apiRef.current.updateRows([{ id: id }]); // changes: {}
            setCellModesModel({
                ...cellModesModel,
                [id]: {
                    ...cellModesModel[id],
                    [field]: { mode: GridCellModes.View },
                },
            });
            alert('Guardado exitosamente');
        } else {
            setCellModesModel({
                ...cellModesModel,
                [id]: {
                    ...cellModesModel[id],
                    [field]: { mode: GridCellModes.Edit },
                },
            });
        }
    };

    const handleCancel = () => {
        if (!selectedCellParams) {
            return;
        }
        const { id, field } = selectedCellParams;
        setCellModesModel({
            ...cellModesModel,
            [id]: {
                ...cellModesModel[id],
                [field]: {
                    mode: GridCellModes.View,
                    ignoreModifications: true,
                },
            },
        });
        alert('Cambios cancelados');
    };

    const handleMouseDown = (event) => {
        // Keep the focus in the cell
        event.preventDefault();
    };

    return (
        <Box m="20px">
            <Header title="Trucks" subtitle="Truck Status Information Board" />
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >
                <Button
                    onClick={handleSaveOrEdit}
                    onMouseDown={handleMouseDown}
                    disabled={!selectedCellParams}
                    variant="outlined"
                    sx={{ color: 'white', borderColor: 'black' }}
                >
                    {cellMode === 'edit' ? 'Save' : 'Edit'}
                </Button>
                <Button
                    onClick={handleCancel}
                    onMouseDown={handleMouseDown}
                    disabled={cellMode === 'view'}
                    variant="outlined"
                    sx={{ ml: 1, color: 'white', borderColor: 'black' }}
                >
                    Cancel
                </Button>
            </Box>
        </Box>
    );
}

EditToolbar.propTypes = {
    cellMode: PropTypes.oneOf(['edit', 'view']).isRequired,
    cellModesModel: PropTypes.object.isRequired,
    selectedCellParams: PropTypes.shape({
        field: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            .isRequired,
    }),
    setCellModesModel: PropTypes.func.isRequired,
};

function SelectEditInputCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext(); // CHANGED FROM CONTEXT TO REF

    const handleChange = async (event) => {
        await apiRef.current.setEditCellValue({
            id,
            field,
            value: event.target.value,
        });
        //apiRef.current.stopCellEditMode({ id, field });
    };

    const plantOptions = ['Mission', 'San Benito'];
    const statusOptions = ['Available', 'Maintenance'];

    return (
        <Select
            value={value}
            onChange={handleChange}
            size="small"
            sx={{ height: 1, width: '100%' }}
            native
            autoFocus
        >
            {field === 'plant' &&
                plantOptions.map((option) => {
                    return <option>{option}</option>;
                })}
            {field === 'status' &&
                statusOptions.map((option) => {
                    return <option>{option}</option>;
                })}
        </Select>
    );
}

const renderSelectEditInputCell = (params) => {
    return <SelectEditInputCell {...params} />;
};

const Trucks = () => {
    const [availability, setAvailability] = useState({});
    const [trucks, setTrucks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const { token, setToken, revokeToken } = useToken();

    // const apiRef = useGridApiRef();

    const urls = [
        `${process.env.REACT_APP_API_URL}/loads/trucksAvailable`,
        `${process.env.REACT_APP_JAP_URL}/trucklist`,
    ];

    const requests = urls.map((url) =>
        axios.get(url, {
            headers: { Authorization: `Bearer ${token}` },
        })
    );

    const createRows = (trucks, availability) => {
        let rows = [];
        for (let i = 0; i < trucks.length; i++) {
            rows.push({
                truckId: trucks[i]._id,
                id: trucks[i].number,
                plantId: trucks[i].plant.number,
                plant: trucks[i].plant.name,
                status: availability[trucks[i].number]
                    ? 'Available'
                    : 'Maintenance',
                comments:
                    Array.isArray(trucks[i].comments) &&
                    trucks[i].comments.length !== 0
                        ? trucks[i].comments[0]['comment']
                        : '',
            });
        }
        console.log(rows);
        return rows;
    };

    const dataFetch = async () => {
        const [trucksAvailable, truckList] = await Promise.all(requests);
        setAvailability(trucksAvailable.data.data.truckAvailability);
        setTrucks(truckList.data);
        setLoading(false);
    };

    useEffect(() => {
        dataFetch();
    }, []);

    useMemo(() => {
        const data = createRows(trucks, availability);
        setRows(data);
    }, [trucks, availability]);

    const [selectedCellParams, setSelectedCellParams] = useState(null);
    const [cellModesModel, setCellModesModel] = useState({});

    const handleCellFocus = useCallback((event) => {
        const row = event.currentTarget.parentElement;
        const id = row.dataset.id;
        const field = event.currentTarget.dataset.field;
        setSelectedCellParams({ id, field });
    }, []);

    const cellMode = useMemo(() => {
        if (!selectedCellParams) {
            return 'view';
        }
        const { id, field } = selectedCellParams;
        return cellModesModel[id]?.[field]?.mode || 'view';
    }, [cellModesModel, selectedCellParams]);

    const handleCellKeyDown = useCallback(
        (params, event) => {
            if (cellMode === 'edit') {
                // Prevents calling event.preventDefault() if Tab is pressed on a cell in edit mode
                event.defaultMuiPrevented = true;
            }
        },
        [cellMode]
    );

    const handleCellEditStop = useCallback((params, event) => {
        // if (params.reason === GridCellEditStopReasons.cellFocusOut) {
        //     event.defaultMuiPrevented = true;
        // }
        event.defaultMuiPrevented = true;
    }, []);

    const processRowUpdate = useCallback(async (newRow, oldRow) => {
        // Make the HTTP request to save in the backend
        // const response = await mutateRow(newRow);
        // setSnackbar({
        //     children: 'User successfully saved',
        //     severity: 'success',
        // });
        // return response;
        console.log(newRow);
    }, []);

    const handleProcessRowUpdateError = useCallback((error) => {
        //setSnackbar({ children: error.message, severity: 'error' });
        console.log(error.message);
    }, []);

    const columns = [
        { field: 'id', headerName: 'Truck Number', flex: 1, editable: false },
        {
            field: 'plant',
            headerName: 'Plant',
            flex: 2,
            editable: false,

            renderEditCell: renderSelectEditInputCell,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 2,
            editable: false,
            renderCell: ({ row: { status } }) => {
                return (
                    <Box display="flex">
                        {status === 'Available' ? (
                            <CheckIcon color="success" />
                        ) : (
                            <ClearIcon color="error" />
                        )}
                        <Typography xs={{ ml: '5px', color: '#fafafa' }}>
                            {status === 'Available'
                                ? 'Available'
                                : 'Maintenance'}
                        </Typography>
                    </Box>
                );
            },
            renderEditCell: renderSelectEditInputCell,
        },
        {
            field: 'comments',
            headerName: 'Comments',
            type: 'string',
            flex: 2,
            editable: true,
        },
    ];

    return (
        <div style={{ height: '77%', width: '97%', marginLeft: '2em' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                onCellKeyDown={handleCellKeyDown}
                cellModesModel={cellModesModel}
                onCellEditStop={handleCellEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                onCellModesModelChange={(model) => setCellModesModel(model)}
                loading={loading}
                slots={{
                    toolbar: () => (
                        <>
                            <EditToolbar
                                selectedCellParams={selectedCellParams}
                                cellMode={cellMode}
                                cellModesModel={cellModesModel}
                                setCellModesModel={setCellModesModel}
                            />
                            <CustomToolbar />
                        </>
                    ),
                    loadingOverlay: TableLoader,
                }}
                sx={{
                    '& .MuiDataGrid-root': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    '& .name-column--cell': {
                        color: '#4caf50' /* Green 500 */,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#24438B' /* Indigo 500 */,
                        borderBottom: 'none',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        /*backgroundColor: '#9575cd'  Deep Purple 300 */
                    },
                    '& .MuiDataGrid-footerContainer': {
                        borderTop: 'none',
                        backgroundColor: '#303f9f' /* Indigo 500 */,
                    },
                    '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                        color: '#fafafa' /* Grey 50 */,
                    },
                }}
                slotProps={{
                    toolbar: {
                        cellMode,
                        selectedCellParams,
                        setSelectedCellParams,
                        cellModesModel,
                        setCellModesModel,
                    },
                    cell: {
                        onFocus: handleCellFocus,
                    },
                }}
            />
        </div>
    );
};

export default Trucks;
