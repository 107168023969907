import { useState } from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

dayjs.extend(utc);
dayjs.extend(timezone);

const CustomDatePicker = ({ ordersDate, setOrdersDate }) => {
    const [value, setValue] = useState(ordersDate);
    
    const handleChange = async (newValue) => {
        setValue(newValue);
        setOrdersDate(newValue);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label="Date of orders"
                value={value}
                disablePast
                views={['year', 'month', 'day']}
                onChange={handleChange}
            />
        </LocalizationProvider>
    );
}

export default CustomDatePicker;
