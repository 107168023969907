import { useState, useCallback, useMemo, useEffect } from 'react';
import {
    DataGrid,
    GridCellModes,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridActionsCellItem,
} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import Header from '../../components/Header';
import PropTypes from 'prop-types';
import useToken from '../../components/Utils/useToken';
import axios from 'axios';
import TableLoader from '../../components/Custom/TableLoader';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CustomDatePicker from '../../components/Custom/CustomDatePicker';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

function EditToolbar(props) {
    const {
        selectedCellParams,
        cellMode,
        cellModesModel,
        setCellModesModel,
        setLoading,
        token,
        disableAutoAssign,
        ordersDate,
        setOrdersDate,
        setRows,
        navigate,
    } = props;

    const handleSaveOrEdit = () => {
        if (!selectedCellParams) {
            return;
        }
        const { id, field } = selectedCellParams;
        if (cellMode === 'edit') {
            setCellModesModel({
                ...cellModesModel,
                [id]: {
                    ...cellModesModel[id],
                    [field]: { mode: GridCellModes.View },
                },
            });
        } else {
            setCellModesModel({
                ...cellModesModel,
                [id]: {
                    ...cellModesModel[id],
                    [field]: { mode: GridCellModes.Edit },
                },
            });
        }
    };

    const handleCancel = () => {
        if (!selectedCellParams) {
            return;
        }
        const { id, field } = selectedCellParams;
        setCellModesModel({
            ...cellModesModel,
            [id]: {
                ...cellModesModel[id],
                [field]: {
                    mode: GridCellModes.View,
                    ignoreModifications: true,
                },
            },
        });
    };

    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    const handleRefresh = async () => {
        setLoading(true);
        const url = `${process.env.REACT_APP_API_URL}/selection/selectPlant`;
        const requests = [
            axios.get(url, {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    startDate: ordersDate.format('YYYY-MM-DD'),
                    endDate: ordersDate.format('YYYY-MM-DD'),
                },
            }),
        ];

        const response = await Promise.all(requests);
        setLoading(false);
        window.location.reload();
    };

    const handleChangeDate = async (event) => {
        setLoading(true);
        navigate(`/orders?date=${ordersDate.format('YYYY-MM-DD')}`);
        const url = `${process.env.REACT_APP_API_URL}/planner/joblist`;
        const requests = [
            axios.get(url, {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    startDate: ordersDate.format('YYYY-MM-DD'),
                    endDate: ordersDate.format('YYYY-MM-DD'),
                },
            }),
        ];

        const response = await Promise.all(requests);
        const jobs = response[0].data.data.jobs;
        setRows(jobs);
        setLoading(false);
    };

    return (
        <Box
            m="10px"
            sx={{
                borderBottom: 1,
                borderColor: 'divider',
                p: 1,
            }}
        >
            <Header title="Orders" subtitle="Order Status Review Board" />
            <Box
                sx={{
                    display: 'flex',
                    marginTop: '-0.5rem',
                }}
            >
                <Button
                    onClick={handleSaveOrEdit}
                    onMouseDown={handleMouseDown}
                    disabled={!selectedCellParams}
                    variant="outlined"
                    sx={{ color: 'white', borderColor: 'black' }}
                >
                    {cellMode === 'edit' ? 'Save' : 'Edit'}
                </Button>
                <Button
                    onClick={handleCancel}
                    onMouseDown={handleMouseDown}
                    disabled={cellMode === 'view'}
                    variant="outlined"
                    sx={{ ml: 1, color: 'white', borderColor: 'black' }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleRefresh}
                    variant="contained"
                    color="success"
                    disabled={disableAutoAssign}
                    sx={{ ml: 5, fontWeight: 'bold' }}
                >
                    Automatic Assignment
                </Button>
                <Box sx={{ marginLeft: 'auto' }}>
                    <CustomDatePicker
                        ordersDate={ordersDate}
                        setOrdersDate={setOrdersDate}
                    />
                    <Button
                        onClick={handleChangeDate}
                        variant="outlined"
                        color="error"
                        sx={{ ml: 1, fontWeight: 'bold', height: '100%' }}
                    >
                        Submit
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

EditToolbar.propTypes = {
    cellMode: PropTypes.oneOf(['edit', 'view']).isRequired,
    cellModesModel: PropTypes.object.isRequired,
    selectedCellParams: PropTypes.shape({
        field: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            .isRequired,
    }),
    setCellModesModel: PropTypes.func.isRequired,
};

const Orders = () => {
    const navigate = useNavigate();

    const [selectedCellParams, setSelectedCellParams] = useState(null);
    const [cellModesModel, setCellModesModel] = useState({});

    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { token, setToken, revokeToken } = useToken();

    const [plants, setPlants] = useState([]);
    const [disableAutoAssign, setDisableAutoAssign] = useState(false);

    const mcAllenTZ = 'America/Chicago';
    const [ordersDate, setOrdersDate] = useState(dayjs().utc().tz(mcAllenTZ));

    const [showError, setShowError] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    let paramDate = searchParams.get('date');

    const prepareParamDate = () => {
        if (!paramDate) {
            const date = ordersDate.format('YYYY-MM-DD');
            paramDate = date;
            navigate(`/orders?date=${date}`);
        }
    };

    const dataFetch = async (reqs) => {
        Promise.all(reqs).then((response) => {
            const data = response[0].data.data.jobs;
            setRows(data);

            if (Array.isArray(data) && !data.length) {
                setDisableAutoAssign(true);
            }

            setPlants(response[1].data);
            setLoading(false);
        });
    };

    const createRequests = (date) => {
        return [
            axios.get(`${process.env.REACT_APP_API_URL}/planner/joblist`, {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    startDate: date.format('YYYY-MM-DD'),
                    endDate: date.format('YYYY-MM-DD'),
                },
            }),
            axios.get(`${process.env.REACT_APP_JAP_URL}/plant`, {
                headers: { Authorization: `Bearer ${token}` },
            }),
        ];
    };

    const defineDate = () => {
        const today = dayjs().utc().tz(mcAllenTZ);
        const date = dayjs(paramDate).utc().tz(mcAllenTZ);
        if (date.isValid() && date.isSameOrAfter(today, 'day')) {
            setOrdersDate(date);
            return date;
        } else {
            setShowError(true);
            return null;
        }
    };

    useEffect(() => {
        if (!paramDate) {
            const date = ordersDate.format('YYYY-MM-DD');
            navigate(`/orders?date=${date}`);
        }
    });

    useMemo(() => {
        prepareParamDate();
        const date = defineDate();
        if (!date) {
            setRows([]);
            return;
        }
        const requests = createRequests(date);
        dataFetch(requests);
    }, []);

    const cellMode = useMemo(() => {
        if (!selectedCellParams) {
            return 'view';
        }
        const { id, field } = selectedCellParams;
        return cellModesModel[id]?.[field]?.mode || 'view';
    }, [cellModesModel, selectedCellParams]);

    const handleCellFocus = useCallback((event) => {
        const row = event.currentTarget.parentElement;
        const id = row.dataset.id;
        const field = event.currentTarget.dataset.field;
        setSelectedCellParams({ id, field });
    }, []);

    const handleCellKeyDown = useCallback(
        (params, event) => {
            if (cellMode === 'edit') {
                // Prevents calling event.preventDefault() if Tab is pressed on a cell in edit mode
                event.defaultMuiPrevented = true;
            }
        },
        [cellMode]
    );

    const handleCellEditStop = useCallback((params, event) => {
        event.defaultMuiPrevented = true;
    }, []);

    const getPlantNames = useMemo(() => {
        let plantNames = [];
        for (let i = 0; i < plants.length; i++) {
            plantNames.push(plants[i].name);
        }
        return plantNames;
    }, [plants]);

    const getPlantId = (plantName) => {
        return plants.find((plant) => plant.name === plantName)._id;
    };

    const processRowUpdate = async (newRow) => {
        setLoading(true);

        const data = {};
        const id = selectedCellParams.id;
        const field = selectedCellParams.field;

        if (field === 'plant') {
            newRow.autoAssign = false;
            data['autoAssignment'] = false;
            data['plant'] = {
                _id: getPlantId(newRow.plant),
                name: newRow.plant,
            };

            const url = `${process.env.REACT_APP_JAP_URL}/job/${id}`;
            const updatedJob = await axios.patch(url, data, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const updatedRows = rows.map((row) =>
                row.jobId === newRow.jobId ? newRow : row
            );
            setRows(updatedRows);

            setCellModesModel({
                ...cellModesModel,
                [id]: {
                    ...cellModesModel[id],
                    [field]: { mode: GridCellModes.View },
                },
            });

            setLoading(false);
            return newRow;
        }
    };

    const handleErrorClose = () => {
        setRows([]);
        setLoading(false);
        setShowError(false);
    };

    const columns = [
        {
            field: 'order',
            headerName: 'Order',
            flex: 1,
            type: 'string',
            headerAlign: 'left',
            align: 'left',
        },
        {
            field: 'client',
            headerName: 'Client',
            flex: 2.5,
            editable: true,
            type: 'string',
            headerAlign: 'left',
            align: 'left',
        },
        {
            field: 'loadYD3',
            headerName: 'Load',
            flex: 1,
            headerAlign: 'left',
            align: 'left',
            type: 'number',
        },
        {
            field: 'targetYD3',
            headerName: 'Target',
            type: 'number',
            flex: 1,
            editable: true,
            headerAlign: 'left',
            align: 'left',
        },
        {
            field: 'time',
            headerName: 'Time',
            flex: 1,
            editable: true,
            type: 'string',
            headerAlign: 'left',
            align: 'left',
        },

        {
            field: 'address',
            headerName: 'Address',
            flex: 3,
            headerAlign: 'left',
            align: 'left',
            type: 'string',
        },
        {
            field: 'mix',
            headerName: 'Mix',
            type: 'string',
            flex: 2,
            editable: true,
            headerAlign: 'left',
            align: 'left',
        },
        {
            field: 'area',
            headerName: 'Area',
            flex: 1,
            editable: true,
            type: 'string',
            headerAlign: 'left',
            align: 'left',
        },
        {
            field: 'plant',
            headerName: 'Plant',
            type: 'singleSelect',
            flex: 1.5,
            editable: true,
            headerAlign: 'left',
            align: 'left',
            valueOptions: getPlantNames,
        },
        {
            field: 'autoAssign',
            headerName: 'Auto',
            type: 'boolean',
            flex: 1,
            editable: false,
            headerAlign: 'left',
            align: 'left',
        },
        {
            field: 'premise',
            headerName: 'Assign Premise',
            type: 'string',
            flex: 2,
            editable: true,
            headerAlign: 'left',
            align: 'left',
        },
        {
            field: 'comments',
            headerName: 'Comments',
            type: 'string',
            flex: 2,
            editable: true,
            headerAlign: 'left',
            align: 'left',
        },
        {
            field: 'actions',
            headerName: 'Cost',
            type: 'actions',
            flex: 1,
            editable: false,
            headerAlign: 'left',
            align: 'center',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<RequestQuoteIcon />}
                    label="Cost"
                    onClick={() => {
                        navigate(`/costs?job=${params.row.jobId}`);
                    }}
                />,
            ],
        },
    ];

    return (
        <div style={{ height: '77%', width: '97%', marginLeft: '2em' }}>
            <Dialog
                open={showError}
                onClose={handleErrorClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        backgroundColor: '#eb1c24',
                    },
                }}
            >
                <DialogTitle
                    fontWeight={'bold'}
                    fontSize={'h3.fontSize'}
                    id="alert-dialog-title"
                    color={'white'}
                >
                    {'Error'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        color={'white'}
                        id="alert-dialog-description"
                    >
                        Invalid or previous date, please try again.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ color: 'white' }}
                        onClick={handleErrorClose}
                        autoFocus
                    >
                        close
                    </Button>
                </DialogActions>
            </Dialog>
            <DataGrid
                rows={rows}
                columns={columns}
                onCellKeyDown={handleCellKeyDown}
                cellModesModel={cellModesModel}
                onCellEditStop={handleCellEditStop}
                onCellModesModelChange={(model) => setCellModesModel(model)}
                processRowUpdate={processRowUpdate}
                loading={loading}
                getRowId={(row) => row.jobId}
                pageSizeOptions={[10, 25, 50]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 25 },
                    },
                }}
                slots={{
                    toolbar: () => (
                        <>
                            <EditToolbar
                                selectedCellParams={selectedCellParams}
                                cellMode={cellMode}
                                cellModesModel={cellModesModel}
                                setCellModesModel={setCellModesModel}
                                setLoading={setLoading}
                                token={token}
                                disableAutoAssign={disableAutoAssign}
                                ordersDate={ordersDate}
                                setOrdersDate={setOrdersDate}
                                setRows={setRows}
                                navigate={navigate}
                            />
                            <CustomToolbar />
                        </>
                    ),
                    loadingOverlay: TableLoader,
                }}
                sx={{
                    '& .MuiDataGrid-root': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    '& .name-column--cell': {
                        color: '#4caf50' /* Green 500 */,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#24438B' /* Indigo 500 */,
                        borderBottom: 'none',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        /*backgroundColor: '#9575cd'  Deep Purple 300 */
                    },
                    '& .MuiDataGrid-footerContainer': {
                        borderTop: 'none',
                        backgroundColor: '#303f9f' /* Indigo 500 */,
                    },
                    '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                        color: '#fafafa' /* Grey 50 */,
                    },
                }}
                slotProps={{
                    toolbar: {
                        cellMode,
                        selectedCellParams,
                        setSelectedCellParams,
                        cellModesModel,
                        setCellModesModel,
                    },
                    cell: {
                        onFocus: handleCellFocus,
                    },
                }}
            />
        </div>
    );
};

export default Orders;
