import { CircularProgress } from '@mui/material';

const TableLoader = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            }}
        >
            {<CircularProgress color="secondary" size={ '4rem' } />}
        </div>
    );
};

export default TableLoader;
