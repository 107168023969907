import * as React from 'react';
import { DataGrid, GridCellEditStopReasons,  GridCellModes,GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import {Box, Typography, useTheme} from "@mui/material";
import { mockDataTeam } from "../../data/mockData";
import { tokens } from "../../theme";
import Header from "../../components/Header";

import PropTypes from 'prop-types';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FilterListIcon from "@mui/icons-material/FilterList";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

export const Table = ({ rows, deleteRow, editRow }) => {
  return (
    <div className="table-wrapper">
      <table className="table">
        <thead>
          <tr>
            <th>plant</th>
            <th className="expand">Description</th>
            <th>unit</th>
            <th>cost</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, idx) => {
            const unitText =
              row.unit.charAt(0).toUpperCase() + row.unit.slice(1);

            return (
              <tr key={idx}>
                <td>{row.plant}</td>
                <td className="expand">{row.description}</td>
                <td>
                  <span className={`label label-${row.unit}`}>
                    {unitText}
                  </span>
                </td>
                <td className="fit">
                  <span className="cost">
                    <DeleteForeverOutlinedIcon
                      className="delete-btn"
                      onClick={() => deleteRow(idx)}
                    />
                    <EditOutlinedIcon
                      className="edit-btn"
                      onClick={() => editRow(idx)}
                    />
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};